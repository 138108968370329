import { AxiosResponse } from 'axios'
import http from '../http'

export default class ContactService {
  static async sendContactForm(data: any): Promise<AxiosResponse> {
    return http.post(`contact/form`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  }
}
