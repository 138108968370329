const AllowedImageFormats: string[] = [
  '.idea',
  '.ai',
  '.ait',
  '.draw',
  '.line',
  '.pdf',
  '.sket',
  '.dwg',
  '.dxf',
  '.bmp',
  '.rle',
  '.dib',
  '.cgm',
  '.cdr',
  '.eps',
  '.epsf',
  '.ps',
  '.emf',
  '.gif',
  '.jpg',
  '.jpeg',
  '.jpe',
  '.jpf',
  '.jpx',
  '.jp2',
  '.j2k',
  '.j2c',
  '.jpc',
  '.pic',
  '.pct',
  '.rtf',
  '.docx',
  '.doc',
  '.pcx',
  '.psd',
  '.pdd',
  '.pxr',
  '.png',
  '.pns',
  '.svg',
  '.svgz',
  '.tga',
  '.vda',
  '.icb',
  '.vst',
  '.tif',
  '.tiff',
  '.wmf',
  '.css',
  '.swf',
]

export default AllowedImageFormats
